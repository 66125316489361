.title {
	font-family: 'Bebas Neue', sans-serif;
	font-weight: bold;
}

.subtitle {
	font-family: 'Montserrat', sans-serif;
	font-optical-sizing: auto;
	font-weight: 500;
}

.simple-button {
	padding: 0.5em 1em;
	background-color: transparent;
	border-radius: 5px;
	border: solid 2px black;
	outline: none;

	color: black;
	font-size: 1rem;
	font-family: var(--base-font);
	text-transform: uppercase;

	cursor: pointer;
}

.simple-button:hover {
	background-color: rgba(0, 0, 0, 0.125);
}

.simple-input {
	height: fit-content;
	margin: 0;
	padding: 0.5em 1em;

	color: black;
	font-size: 1rem;

	outline: none;
	border: none;
	border-bottom: 2px solid black;
}

.icon-button {
	cursor: pointer;
}
.icon-button:hover {
	color: red;
}
