.page {
	width: 100%;
	min-height: 100vh;

	padding: 1em;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
