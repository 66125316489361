.player-board {
	margin-top: 3em;

	display: flex;
	flex-direction: column;
	gap: 3em;
}

.player-board-tile {
	min-width: 800px;

	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 1em;
}

.player-board-tile span {
	font-size: 3rem;
	text-transform: capitalize;
}

.player-board-tile .icon-button {
	font-size: 3rem;
}

.player-board-tile .icon-button:last-of-type:hover {
	color: green;
}

.player-info {
	position: relative;
	min-width: 500px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 1em;

	font-size: 3rem;
	text-transform: capitalize;
}

.player-info span {
	position: absolute;

	top: 60%;
	right: 20%;

	transform: translateY(-50%);
}

.scoreboard-actions {
	margin-top: 1em;

	display: flex;
	align-items: center;
	gap: 1em;
}

.scoreboard-actions .simple-button {
	font-size: 1.5rem;
}

@media (max-width: 1280px) {
	.player-info {
		font-size: 2rem;
	}

	.player-board-tile .icon-button:first-of-type {
		color: red;
	}

	.player-board-tile .icon-button:last-of-type {
		color: green;
	}
}

@media (max-width: 780px) {
	.title {
		font-size: 2rem;
		text-align: center;
	}

	.player-board {
		width: 100%;
		margin-top: 1em;
		gap: 1em;
	}

	.player-board-tile {
		width: 100%;
		min-width: unset;

		display: flex;
		align-items: center;
	}

	.player-board-tile span {
		font-size: 1.5rem;
	}

	.player-board-tile .icon-button {
		font-size: 2rem;
	}

	.player-info {
		width: 100%;
		min-width: unset;
		font-size: 1.5rem;
		gap: 0.25em;
	}

	.scoreboard-actions .simple-button {
		font-size: 0.75rem;
		min-height: 100px;
	}
}
